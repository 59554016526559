<template>
  <div>
    <div class="container">
        <header-with-line header="Neuro-Rehabilitation" />
        <div class="row">
            <div class="col-12 mx-auto">
                <div class="img-container text-center mt-5">
                    <img class="img-fluid" src="../../assets/img/decorations/path_to_self_determined_life.svg" alt="Fleckdekoration">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mx-auto mb-3 text-primary fs-4 mt-5 text-justify">
                <p>
                  In unserem Therapiezentrum bieten wir eine umfassende Grundversorgung in den Bereichen Physiotherapie,
                  Ergotherapie und Logopädie an und behandeln alle gängigen Krankheitsbilder. Unser Schwerpunkt liegt
                  jedoch auf der Neuro-Rehabilitation. Wir sind spezialisiert auf neurologische Erkrankungen wie <span class="text-bold">Schlaganfall,
                  Parkinson oder Multiple Sklerose</span> und bieten diese spezialisierte Therapie sowohl für Erwachsene
                  als auch für Kinder an.
                </p>
                <p>
                  Mit unserem interdisziplinären Team erstellen wir individuelle Therapiepläne, die genau auf deine Stärken
                  und Bedürfnisse abgestimmt sind. Besonders bei Kindern arbeiten wir gezielt mit Patient*innen,
                  die an Skoliose oder Mukoviszidose leiden, um ihre Beweglichkeit und Selbstständigkeit im Alltag zu fördern.
                  Unser Ziel ist es, deine Lebensqualität zu verbessern oder zu erhalten.
                </p>
            </div>
        </div>
      </div>
    <section class="py-3">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center gy-2 text-center mb-2">
          <div class="col-lg-4 col-sm-12" id="life-quality-img">
            <img class="img-fluid lifequality-img-box" src="../../assets/img/leistungen/neuro_reha/we_increase.png" alt="Lebensqualität steigern" />
          </div>
          <div class="col-lg-8 col-sm-12 lifequality-box text-white py-0" id="life-quality-table">
              <img class="img-fluid my-2" src="../../assets/img/decorations/how_we_improve_quality_life.svg" alt="Liniendekoration" />
              <p class="fs-5 text-left">
                Unsere interdisziplinäre Behandlung zielt darauf ab, dir zu helfen,
                folgende Ziele zu erreichen:
              </p>
              <ul class="custom-list text-left fs-5 pl-0">
                  <li>
                      <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                      <p>Wir unterstützen dich dabei, alltägliche Aufgaben wie Anziehen, Essen oder Gehen wieder selbstständig auszuführen.</p>
                  </li>
                  <li>
                      <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                      <p>Ziel ist es, Beweglichkeit und Kraft durch gezielte Therapie zurückzugewinnen.</p>
                  </li>
                  <li>
                      <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                      <p>Wir arbeiten daran, deine Konzentration, Gedächtnis und Sprachfähigkeiten zu stärken, damit du dich sicher im Alltag bewegen kannst.</p>
                  </li>
                  <li>
                      <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                      <p>Unsere Therapeut*innen helfen dir, emotionale Belastungen wie Ängste und Depressionen zu bewältigen, um deine Lebensqualität zu verbessern.</p>
                  </li>
                  <li>
                      <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                      <p>Durch gezielte Therapieformen unterstützen wir dich dabei, wieder aktiv am sozialen Leben teilzunehmen und deine Lebensfreude zurückzugewinnen.</p>
                  </li>
              </ul>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center gy-2 text-center my-2">
          <div class="col-lg-8 col-md-12 lifequality-box text-white order-lg-1 order-2">
            <img class="img-fluid mb-2" src="../../assets/img/decorations/neuro_rehabilitation_helps_with.svg" alt="Liniendekoration" />
              <div class="row text-bold">
                <div class="col-md-6 col-xs-12">
                  <ul class="custom-list fs-5 pl-0 py-0 m-0">
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Schlaganfall</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Parkinson </p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Schädel-Hirn-Trauma</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Multiple Sklerose</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Querschnittslähmung</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Poly- Neuropathie</p>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xs-12">
                  <ul class="custom-list fs-5 pl-0 m-0">
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Guillain-Barré-Syndrom</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Spinale Muskelatrophie</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Amyotrophe Lateralsklerose</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Trisomie 21 (Down-Syndrom)</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>Tumore des Nervensystems</p>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <p>uvm.</p>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
          <div class="col-lg-4 col-md-12 order-lg-2 order-1">
            <img class="img-fluid lifequality-img-box" src="../../assets/img/leistungen/neuro_reha/neuro_reha_help.png" alt="Lebensqualität steigern">
          </div>
        </div>
      </div>
    </section>
    <section class="py-3">
      <div class="container">
        <div class="row text-center g-3">
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Für wen ist Neuro-Rehabilitation geeignet?'"
                    :subTitle="'Mehr als nur Kopfsache.'"
                    :imgSrc="require('../../assets/img/leistungen/neuro_reha/for_whom_reha.png')"
                    :imgAlt="''"
                    :modalText="`
                        <ul>
                            <li>
                                <p><span class='text-bold'>Schlaganfall:</span> Hilft dir, Mobilität, Sprache und Denkfähigkeiten wiederzuerlangen.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Hirnverletzungen:</span> Unterstützt die Wiederherstellung motorischer und geistiger Fähigkeiten nach Unfällen oder Stürzen.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Neurologische Erkrankungen:</span> Verlangsamt den Verlauf von Parkinson, Multiple Sklerose oder Alzheimer und verbessert deine Lebensqualität.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Rückenmarksverletzungen:</span> Fördert Beweglichkeit und Selbstständigkeit nach einer Verletzung des Rückenmarks.</p>
                            </li>
                        </ul>`"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Was passiert in der Therapie?'"
                    :subTitle="'Unser grüner Faden für mehr Lebensqualität.'"
                    :imgSrc="require('../../assets/img/leistungen/neuro_reha/what_happens.png')"
                    :imgAlt="''"
                    :modalText="`
                        <ul>
                            <li>
                                <p><span class='text-bold'>Erstgespräch:</span> Wir besprechen deine Beschwerden und Ziele.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Diagnostik:</span> Tests helfen uns, deine Stärken und Schwächen zu analysieren.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Behandlungsplan:</span> Wir erstellen einen individuellen Therapieplan.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Therapie:</span> Wir arbeiten gezielt an der Verbesserung deiner Fähigkeiten.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Evaluation:</span> Wir prüfen deinen Fortschritt und passen die Therapie an.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Abschluss:</span> Wir sichern deinen Erfolg und planen deine langfristige Stabilität.</p>
                            </li>
                        </ul>
                        `"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Was macht unsere Therapie innovativ & Individuell?'"
                    :subTitle="'Maßgeschneiderte Lösungen für deine Gesundheit.'"
                    :imgSrc="require('../../assets/img/leistungen/neuro_reha/what_does_therapy.png')"
                    :imgAlt="''"
                    :modalText="`
                        <ul>
                            <li>
                                <p><span class='text-bold'>Individuelle Anpassung:</span> Unsere Behandlungen werden genau auf deine Bedürfnisse abgestimmt.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Cube Sports Funktionswand:</span> Flexibles Training – auch bei eingeschränkter Mobilität oder im Rollstuhl.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Alltagsnahes Training:</span> Wir gestalten die Therapie so, dass sie dir im Alltag hilft, selbstständiger zu werden.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Modulares System:</span> Jede Therapieeinheit wird gezielt auf deine Fortschritte angepasst.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Einzigartige Ausstattung:</span> Besonders wertvoll für neurologische Patient*innen, wo Standardlösungen oft nicht ausreichen.</p>
                            </li>
                        </ul>
                    `"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Wer hat Anspruch auf Therapie?'"
                    :subTitle="'Spezielle Behandlung für besondere Anforderungen.'"
                    :imgSrc="require('../../assets/img/leistungen/neuro_reha/who_is_entitled.png')"
                    :imgAlt="''"
                    :modalText="`
                        <ul>
                            <li>
                                <p><span class='text-bold'>Neurologische Grunderkrankungen:</span> Anspruch auf Therapie außerhalb des Regelfalls, mit extra Budgetierung durch deine Ärzt*innen.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Intensivere Behandlung:</span> Spezialisierte Therapie zur Verbesserung der Lebensqualität und Selbstständigkeit.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Verordnungs-Service:</span> Unterstützung bei der Nachbestellung und Verlängerung von Verordnungen.</p>
                            </li>
                            <li>
                                <p><span class='text-bold'>Beratung:</span> Wir helfen dir persönlich und stellen dir bei Bedarf Verordnungsvorlagen zur Verfügung.</p>
                            </li>
                        </ul>
                    `"
                />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import ShieldCard from '../Ui/ShieldCard.vue';

export default {
    name: 'SpeechTherapy',
    components: {
        HeaderWithLine,
        ShieldCard
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>

<style scoped>
.img-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-bold {
  font-weight: bold;
}

.card-img-left {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.custom-list li {
    display: flex;
    align-items: top;
}

.custom-list img {
    height: 20px;
    margin-right: 10px;
}

.lifequality-img-box {
    border-radius: 10px;
    border: 4px solid var(--bs-tertiary);
}

.lifequality-box {
    background-color: var(--bs-background);
    border: 4px solid var(--bs-primary);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
