<template>
    <div class="container">
        <header-with-line header="Was ist Physiotherapie?" />
        <div class="row">
            <div class="mx-auto">
                <div class="img-container text-center my-5">
                    <img class="img-fluid" src="../../assets/img/decorations/life_is_movement.svg" alt="Fleckdekoration">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!--
                <video class="object-fit-cover" autoplay muted loop playsinline width="100%" poster="physio_leistung.jpg">
                    <source src="physio_leistung_sm.mp4" media="(max-width: 400px)" type="video/mp4">
                    <source src="physio_leistung_md.mp4" media="(max-width: 550px)" type="video/mp4">
                    <source src="physio_leistung_lg.mp4" media="(max-width: 930px)" type="video/mp4">
                    <source src="physio_leistung_xl.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                -->
                <div class="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/uXC6YUvB16E?autoplay=1&disablekb=1&loop=1&mute=1&modestbranding=1&color=white&iv_load_policy=3&playlist=uXC6YUvB16E"
                    >
                    </iframe>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 my-3">
                <p class="text-primary fs-4 text-justify">
                  Der Schwerpunkt der Physiotherapie ist der Bewegungsapparat - hierzu zählen Muskeln, Sehnen, Bänder,
                  Bindegewebe, Nerven und Knochen. Die Biomechanik des Menschen soll erhalten, gefördert und verbessert
                  werden, sodass ein natürliches Bewegen möglich ist.
                  Dies können Therapeut*innen durch ein breites Spektrum von passiven sowie aktiven Maßnahmen erreichen
                  und somit physiologisch Körperstrukturen anregen oder auch gereizte Körperbereiche dämpfen.
                </p>
            </div>
        </div>
        <div class="row text-center g-3">
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Krankengymnastik (KG)'"
                    :subTitle="'Bewegung als Medizin'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/physical_therapy.png')"
                    :imgAlt="'Krankengymnastik'"
                    :modalText="'Krankengymnastik ist eine aktive Therapieform, bei der der Patient durch gezielte Übungen und Bewegungen die Beweglichkeit und Kraft seiner Muskulatur verbessern soll. Ziel ist es, die Beweglichkeit der Gelenke zu erhalten oder wiederherzustellen, die Muskulatur zu kräftigen und die Koordination zu verbessern.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Krankengymnastik, zentrales Nervensystem (KG ZNS)'"
                    :subTitle="'Für deine Selbstständigkeit im Alltag'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/pt_zns.png')"
                    :imgAlt="'KG ZNS (Bobath, Vojta oder PNF)'"
                    :modalText="'KG ZNS ist eine spezialisierte Physiotherapie für neurologische Erkrankungen wie Schlaganfall, Parkinson oder Multiple Sklerose. Ziel ist es, durch gezielte Übungen Beweglichkeit, Koordination und Kraft zu verbessern, um dir zu helfen, deine Selbstständigkeit im Alltag wiederzuerlangen. Mit bewährten Konzepten wie Bobath, Vojta oder PNF unterstützen speziell ausgebildete Therapeut*innen Erwachsene und Kinder auf ihrem Weg zu mehr Unabhängigkeit.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Manuelletherapie (MT)'"
                    :subTitle="'Sanft zu mehr Beweglichkeit'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/manual_therapy.png')"
                    :imgAlt="'Manuelletherapie'"
                    :modalText="'Die manuelle Therapie zielt darauf ab, Schmerzen zu lindern und die Beweglichkeit zu verbessern. Mit gezieltem Druck und sanften Mobilisationen lösen unsere Therapeut*innen Blockaden und fördern die Durchblutung. Die Behandlung wird individuell angepasst und hilft bei Beschwerden wie Rückenschmerzen, Nackenverspannungen, Gelenkproblemen oder Sportverletzungen. Ziel ist es, deinen Körper wieder ins Gleichgewicht zu bringen und dein Wohlbefinden zu steigern.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Manuelle Lymphdrainage (MLD)'"
                    :subTitle="'Für deinen gesunden Lymphfluss'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/manual_lymphatic_drainage.png')"
                    :imgAlt="'Manuelle Lymphdrainage (MLD)'"
                    :modalText="'Mit sanften Griffen regt die manuelle Lymphdrainage den Lymphfluss an und hilft, Stauungen im Gewebe zu lösen. So unterstützt sie den Abtransport von überschüssiger Flüssigkeit und Schadstoffen. Diese Therapie ist besonders wirksam bei Lymphödemen, Schwellungen nach Operationen oder chronischer Veneninsuffizienz. Sie fördert die Entgiftung des Körpers und stärkt dein Immunsystem.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Skoliosetherapie nach Schroth'"
                    :subTitle="'Spezialisiert auf Skoliose'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/schroth_scoliosis_therapy.png')"
                    :imgAlt="'Schroth-Therapie'"
                    :modalText="'Bei der Behandlung von Skoliose arbeiten wir eng mit Orthopäd*innen zusammen. Durch gezielte Übungen und die Dreh-Winkel-Atmung lernst du, deine Haltung zu korrigieren und die Wirbelsäule zu entlasten. Ziel ist es, die Krümmung der Wirbelsäule zu verlangsamen, die Muskulatur zu stärken, die Lungenfunktion zu verbessern und die Haltung zu stabilisieren.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Physiotherapie bei Mukoviszidose'"
                    :subTitle="'Freier atmen, besser leben'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/cystic_fibrosis_therapy.png')"
                    :imgAlt="'Physiotherapie bei Mukoviszidose'"
                    :modalText="'Mit gezielter Atemtherapie, Schleimlösung und Bewegungstherapie helfen wir dir, die Atemwege zu befreien, Infektionen zu reduzieren und deine Fitness zu steigern. Unser Ziel ist es, deine Atmung zu verbessern und deine Lebensqualität nachhaltig zu erhöhen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Craniomandibuläre Dysfunktion (CMD)'"
                    :subTitle="'Für ein entspanntes Kiefergelenk'"
                    :imgSrc="require('../../assets/img/leistungen/physiotherapy/craniomandibular_dysfunction.png')"
                    :imgAlt="'Craniomandibuläre Dysfunktion (CMD)'"
                    :modalText="'Bei CMD arbeiten wir eng mit Zahnärztinnen und Kieferorthopädinnen zusammen, um Beschwerden im Kiefer gezielt zu behandeln. Mit individuellen Übungen und manueller Therapie lindern wir Schmerzen, verbessern die Kieferfunktion und fördern eine gesunde Körperhaltung.'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import ShieldCard from '@/components/Ui/ShieldCard.vue';

export default {
    name: 'PhysioTherapy',
    components: {
        HeaderWithLine,
        ShieldCard
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style scoped>
.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-stroked {
    font-family: 'HP Simplified';
    color: white;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 3px #08425e;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1199px){
    .text-stroked
    {
        font-size: 2.7vw;
        -webkit-text-stroke: 0.1vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 4.3vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 767px) {
    .text-stroked {
        font-size: 4vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 576px) {
    .text-stroked {
        font-size: 5.7vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }
}

p {
  hyphens: auto;
}
</style>
