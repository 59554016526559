<template>
    <b-card
        img-left
        :img-src="imgSrc"
        :title="title"
        class="
            card-left
            bg-primary
            text-white
            text-center
            text-break
            d-flex
            justify-content-center
            align-items-center
            shadow
        "
    />
</template>

<script>
import { BCard } from 'bootstrap-vue';

export default {
    name: 'ThreadCardLeft',
    components: {
        BCard
    },
    props: {
        title: {
            type: String,
            required: true
        },
        imgSrc: {
            type: String,
            required: false
        },
        imgAlt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
        };
    },
    computed: {
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
.card-left {
    border-color: #b0c34a; /* Устанавливаем цвет бордера */
    border-style: solid; /* Устанавливаем стиль бордера */
    padding: 0;
    border-width: 5px 0 5px 5px;
    border-radius: 10px 0 0 10px;
}

.card-left img {
    max-height: 280px;
    border-radius: 5px 0 0 5px;
}

@media screen and (max-width: 1199px){
    .card-left img {
        max-height: 200px;
    }
}

@media screen and (max-width: 991px){
    .card-left {
        border-width: 5px 5px 5px 5px;
        border-radius: 10px 10px 10px 10px;
    }

    .card-left img {
        max-height: 150px;
    }

    .card-left {
        margin-bottom: 20px;
    }
}
</style>
