<template>
    <layout-default :sections="sections" :activeSection="activeSection">
        <section class="bg-white py-1" id="neuro-rehabilitation" data-description="Neurorehabilitation">
            <neuro-rehabilitation />
            <div class="text-center py-4">
                <appointment-reservation />
            </div>
        </section>

        <section class="bg-light py-1" id="speech-therapy" data-description="Logopädie">
            <speeh-therapy />
            <div class="text-center py-4">
                <appointment-reservation />
            </div>
        </section>

        <section class="bg-white py-1" id="physio-therapy" data-description="Physiotherapie">
            <physio-therapy />
            <div class="text-center py-4">
                <appointment-reservation />
            </div>
        </section>

        <section class="bg-light py-1" id="ocupational-therapy" data-description="Ergotherapie">
            <ocupational-therapy />
            <div class="text-center py-4">
                <appointment-reservation />
            </div>
        </section>

        <section class="bg-white py-0" id="green-thread" data-description="Grünen Faden">
            <div class="container">
                <green-thread />
             </div>
        </section>

    </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import GreenThread from '../components/Leistungen/GreenThread.vue';
import SpeehTherapy from '../components/Leistungen/SpeechTherapy.vue';
import PhysioTherapy from '../components/Leistungen/PhysioTherapy.vue';
import OcupationalTherapy from '../components/Leistungen/OcupationalTherapy.vue';
import AppointmentReservation from '../components/Ui/AppointmentReservation.vue';
import NeuroRehabilitation from '../components/Leistungen/NeuroRehabilitation.vue';

export default {
    name: 'Leistungen',
    components: {
        LayoutDefault,
        GreenThread,
        SpeehTherapy,
        PhysioTherapy,
        OcupationalTherapy,
        AppointmentReservation,
        NeuroRehabilitation
    },
    data() {
        return {
            sections: [],
            activeSection: ''
        };
    },
    mounted() {
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    },
    methods: {
        updateActiveSection(id) {
            this.activeSection = id;
        }
    }
};
</script>
<style scoped>
</style>
