<template>
    <div class="container">
        <header-with-line header="Was ist Logopädie?" />
        <div class="row">
            <div class="col-12 mx-auto">
                <div class="img-container text-center mt-5">
                    <img class="img-fluid" src="../../assets/img/decorations/my_world_ends_where.svg" alt="Fleckdekoration">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mx-auto mb-3">
                <p class="text-primary fs-4 mt-5 text-justify">
                  Logopädinnen und Logopäden erstellen Erstbefunde und behandeln alle Formen von Sprech-, Sprach-,
                  Stimm- und Schluckstörungen bei Menschen aller Altersgruppen. Ihre Ziele umfassen die Wiederherstellung,
                  Verbesserung oder Kompensation von krankheits- oder entwicklungsbedingten Einschränkungen der kommunikativen Fähigkeiten.
                  Auch Probleme beim Lesen oder Schreiben, die Anzeichen einer beeinträchtigten Sprachentwicklung sind,
                  werden von Logopädinnen und Logopäden behandelt. Redeflussstörungen wie Stottern oder Wortfindungsstörungen bei Aphasie,
                  die verschiedene Ursachen haben können, fallen ebenfalls in ihr Behandlungsspektrum.
                </p>
            </div>
        </div>
        <div class="row text-center g-3">
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Sprachstörungen'"
                    :subTitle="'Worte wiederfinden'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/speech_disorders.png')"
                    :imgAlt="'Sprachstörungen'"
                    :modalText="'Sprachstörungen beeinträchtigen die Fähigkeit, sich klar und verständlich auszudrücken. In der Logopädie arbeiten wir daran, die Sprachentwicklung zu fördern und verloren gegangene Fähigkeiten wiederherzustellen. Mit gezielten Übungen unterstützen wir dich dabei, Wortfindungsprobleme, Aussprachefehler oder Schwierigkeiten beim Satzbau zu überwinden und deine Kommunikationsfähigkeit zu verbessern.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Sprechprobleme'"
                    :subTitle="'Deutlich sprechen lernen'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/speech_problems.png')"
                    :imgAlt="'Sprechprobleme'"
                    :modalText="'Sprechprobleme beeinflussen die Fähigkeit, Laute klar und flüssig zu formen. Unsere Therapie hilft dir, Schwierigkeiten wie Stottern, Lispeln oder undeutliche Aussprache zu verbessern. Mit individuell abgestimmten Übungen unterstützen wir dich dabei, deine Sprechweise klarer und verständlicher zu machen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Stimmstörungen'"
                    :subTitle="'Deiner Stimme Kraft geben'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/voice_disorders.png')"
                    :imgAlt="'Stimmstörungen'"
                    :modalText="'Stimmstörungen beeinträchtigen die Klangqualität, Lautstärke oder Belastbarkeit der Stimme. In der Therapie arbeiten wir daran, deine Stimme zu stärken und Stimmprobleme wie Heiserkeit, Stimmausfall oder Erschöpfung beim Sprechen zu beheben. Mit gezielten Übungen helfen wir dir, deine Stimme gesund und belastbar zu halten.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Schluckstörungen'"
                    :subTitle="'Sicher essen und trinken'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/swallowing_disorders.png')"
                    :imgAlt="'Schluckstörungen'"
                    :modalText="'Schluckstörungen können das Essen, Trinken und Schlucken erschweren. In der Logopädie helfen wir dir mit speziellen Übungen, deine Schluckfunktion zu verbessern und das Risiko von Verschlucken oder Husten zu reduzieren. Unser Ziel ist es, dir ein sicheres und angenehmes Schlucken zu ermöglichen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Lese-Rechtschreib-Schwäche'"
                    :subTitle="'Besser lesen und schreiben lernen'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/reading_spelling_weakness.png')"
                    :imgAlt="'Lese-Rechtschreib-Schwäche'"
                    :modalText="'Eine Lese-Rechtschreib-Schwäche erschwert das Erlernen von Lesen und Schreiben. In der Logopädie unterstützen wir Kinder und Erwachsene mit gezielten Übungen, ihre Fähigkeiten zu verbessern. Unser Ziel ist es, Buchstaben, Wörter und Sätze leichter zu erkennen und sicherer im Umgang mit Sprache zu werden.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Trachealkanül-management'"
                    :subTitle="'Sicher atmen und sprechen'"
                    :imgSrc="require('../../assets/img/leistungen/speech_therapy/tracheal_cannula_management.png')"
                    :imgAlt="'Trachealkanül-management'"
                    :modalText="'Das Trachealkanülen-Management unterstützt Menschen, die eine Kanüle zur Atmung benötigen. In der Logopädie helfen wir dir, den Umgang mit der Kanüle zu erlernen, die Atemwege freizuhalten und, wenn möglich, deine Sprechfähigkeit wiederherzustellen. Wir begleiten dich auf dem Weg zu mehr Lebensqualität und Selbstständigkeit.'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import ShieldCard from '../Ui/ShieldCard.vue';

export default {
    name: 'SpeechTherapy',
    components: {
        HeaderWithLine,
        ShieldCard
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style scoped>
.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
