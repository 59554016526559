<template>
  <div>
    <b-card
      :title="title"
      :img-src="computedImgSrc"
      :img-alt="imgAlt"
      img-top
      class="disruption-shield bg-primary text-white shadow"
      @click="openModal"
    >
    </b-card>
    <LeistungModal
        v-model="modalShow"
        :title="title"
        :subTitle="subTitle"
        :modalText="modalText"
        :imgSrc="computedImgSrc"
    />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import blanccoImage from '../../assets/img/decorations/shild_blanco.png';
import LeistungModal from '../Leistungen/LeistungModal.vue';

export default {
    name: 'ShieldCard',
    components: {
        BCard,
        LeistungModal
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: false
        },
        imgSrc: {
            type: String,
            required: false
        },
        imgAlt: {
            type: String,
            required: true
        },
        modalText: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            blanccoImage: blanccoImage,
            modalShow: false
        };
    },
    computed: {
        computedImgSrc() {
            return this.imgSrc || this.blanccoImage;
        }
    },
    methods: {
        openModal() {
            this.modalShow = true;
        }
    }
};
</script>
<style scoped>
.disruption-shield {
  position: relative;
  border: 5px solid #b0c34a;
  border-radius: 0px 0px 50px 50px;
}

.disruption-shield:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  clip-path: polygon(0 10%, 100% 0%, 0 100%);
  /* clip-path: polygon(100% 0, 95% 27%, 90% 42%, 83% 56%, 70% 71%, 55% 82%, 31% 93%, 0 100%, 0 18%); */
  background: linear-gradient(135deg, #b0c34a 10%, #07425e 90%);
  opacity: 0;
  transition: opacity 0.3s;
}

@media (hover: none) and (pointer: coarse) {
  .disruption-shield:after {
    opacity: 1;
  }
}

.disruption-shield:hover:after {
  opacity: 1;
}

.card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110px;
}

@media screen and (max-width: 1399px) {
  .card-body {
    min-height: 130px;
  }
}

@media screen and (max-width: 1199px) {
  .card-body {
    min-height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .card-body {
    min-height: 122px;
  }
}

@media screen and (max-width: 767px) {
  .card-body {
    min-height: auto;
  }
}

img {
  border-bottom: 5px solid #b0c34a;
}
</style>
