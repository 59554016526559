<template>
    <div>
        <!-- Header -->
        <div class="row">
            <div class="col-lg-12 mt-5">
                <div class="fs-1 text-bold text-primary text-center">Für deinen Therapie Erfolg haben wir<br>den <span class="text-tertiary text-decoration-underline">Grünen</span> Faden</div>
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- First block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
              <thread-card-left
                    :title="'Erstgepräch und Anamnese'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/first_speak.png')"
                    :imgAlt="'Erstgepräch und Anamnese'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Erfassung der persönlichen Geschichte und Symptome des Patienten. Klärung der Behandlungsziele und Erwartungen.'"
                    :img-src="require('../../assets/img/leistungen/green_thread/anamnese.png')"
                    :imgAlt="'Erstgespräch und Anamnese'"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Second block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
              <thread-card-left
                    :title="'Diagnostische Phase'"
                    :img-src="require('../../assets/img/leistungen/green_thread/diagnostic_stage.png')"
                    :imgAlt="'Diagnostische Phase'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Durchführung von Tests und Bewertung der Ergebnisse. Identifizierung von Stärken, Schwächen und zugrunde liegenden Problemen.'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/test.png')"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Third block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
                <thread-card-left
                    :title="'Behandlungsplanung'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/treatment_planning.png')"
                    :imgAlt="'Behandlungsplanung'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Entwicklung eines individuellen Behandlungsplans basierend auf den diagnostischen Ergebnissen. Festlegung der therapeutischen Ansätze und Interventionen.'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/development.png')"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Fourth block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
                <thread-card-left
                    :title="'Therapiephase'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/therapy_phase.png')"
                    :imgAlt="'Therapiephase'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Durchführung der vereinbarten Heilmitelmethoden. Arbeit an der Bewältigung von Problemen, Veränderung von Denkmustern und Verhaltensweisen.'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/implementation.png')"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Fifth block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
                <thread-card-left
                    :title="'Evaluation & Anpassung'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/evaluation.png')"
                    :imgAlt="'Evaluation & Anpassung'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Regelmäßige Überprüfung des Fortschritts und der Wirksamkeit der Therapie. Anpassung des Behandlungsplans bei Bedarf.'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/regular.png')"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Sixth block -->
        <div class="row">
            <div class="col-lg-6 pr-0">
                <thread-card-left
                    :title="'Abschlussphase'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/final_phase.png')"
                    :imgAlt="'Abschlussphase'"
                />
            </div>
            <div class="col-lg-6 pl-0">
                <thread-card-right
                    :text="'Zusammenfassung der Therapieergebnisse und Reflexion des Behandlungsprozesses. Entwicklung von Strategien zur langfristigen Stabilität und Rückfallprävention.'"
                    :imgSrc="require('../../assets/img/leistungen/green_thread/zusammenfassung.png')"
                />
            </div>
        </div>
        <!-- Green bar -->
        <div class="row">
            <div class="col-lg-12 green-bar-container">
                <div class="green-bar"></div>
            </div>
        </div>
        <!-- Yarn ball -->
        <div class="row">
            <div class="col-lg-12 text-center mb-5">
                <img class="img-fluid yarnball shadow" src="../../assets/img/leistungen/green_thread/yarnball.svg" alt="Green Thread">
            </div>
        </div>
    </div>
</template>
<script>
import ThreadCardLeft from '../Ui/ThreadCardLeft.vue';
import ThreadCardRight from '../Ui/ThreadCardRight.vue';

export default {
    name: 'GreenThread',
    components: {
        ThreadCardLeft,
        ThreadCardRight
    },
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style scoped>

.green-bar {
    width: 5px;
    height: 50px;
    background-color: #b0c34a;
}

.green-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.yarnball {
    width: 30%;
    height: auto;
    border-radius: 50%;
}

/*
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
*/

@media (max-width: 576px) {
    img {
        width: 100%;
    }
    .content {
        flex-direction: column;
    }
}
</style>
