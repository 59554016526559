<template>
    <div class="container">
        <header-with-line header="Was ist Ergotherapie?"  class="mb-4"/>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-9 col-md-12 mx-auto pb-4">
              <img class="img-fluid" src="../../assets/img/decorations/with_heart_hand_mind.svg" alt="Fleckdekoration">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
              <div class="video-container">
                <iframe
                    src="https://www.youtube.com/embed/pvOvXU8WwK4?autoplay=1&disablekb=1&loop=1&mute=1&modestbranding=1&color=white&iv_load_policy=3&playlist=pvOvXU8WwK4"
                >
                </iframe>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mx-auto">
                <p class="text-primary fs-4 text-justify py-4">
                  Ein Die Therapieformen der Ergotherapie sind sehr vielfältig und können in allen Altersklassen von Nöten sein. Ergotherapie fokussiert sich auf wesentliche Behandlungssäulen:
                  Sensomotorik (Interaktion Gehirn und Körper), Kognition (mentale Funktionen) sowie Psycho-bzw. Sozioemotinalität (emotionale Ausgeglichenheit, Umgang mit Krankheitssymptomen, Eigenmotivation etc.)
                  Lernblockaden bei Kindern, Entwicklungsverzögerung (erworben oder angeboren), soziale Interaktonsschwierigkeiten, emotionale “Hürden” im Kindes- und Jugendalter
                  (Traurigkeit, Wut, emotionale Regulationsschwierigkeiten, Unterstützung bei emotionaler familiäre Belastung z.B. durch Familiensystemischen Therapieansatz um Kinder zu stärken.)
                  sind klassische Behandlungsfelder der Ergothrapie.
                  Neurologische Erkrankungen/Beeinträchtigungen der Gehirnfunktionen (z.B. nach Schlaganfall, M. Parkinson, MS etc.)
                  Nervenerkrankungen, orthopädische und degenerative Erkrankungen stehen bei uns ebenso im Fokus wie auch Erkrankungen des rheumatischen Formenkreises.
                  Wir bieten einen individuellen, auf das Beschwerdebild des Patienten abgestimmten, Behandlungsplan an. Text hinzufügen
                </p>
            </div>
        </div>
        <div class="row text-center g-3 mb-4">
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Sensomotorisch-perzeptive Behandlung'"
                    :subTitle="'Gehirn und Körper im Einklang'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/sensorimotor_perceptive.png')"
                    :imgAlt="'Sensomotorisch- perzeptiv'"
                    :modalText="'Eine gute Verbindung zwischen Gehirn und Körper ist entscheidend für koordinierte Bewegungen und geistige Leistung. Störungen der Sensorik können zu Bewegungs- und Denkschwierigkeiten führen. Mit gezielten Übungen und fundierten Behandlungsplänen helfen wir, Gehirn und Körper wieder optimal zusammenarbeiten zu lassen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Hirnleistungstraining'"
                    :subTitle="'Mentale Stärke fördern'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/cognitive_training.png')"
                    :imgAlt="'Hirnleistungstraining'"
                    :modalText="'Im Mittelpunkt dieser Therapie steht die gezielte Stimulation kognitiver Fähigkeiten wie Konzentration, Merkfähigkeit und Aufmerksamkeit. Gemeinsam mit unseren Therapeut*innen arbeitest du an deinen Defiziten – mithilfe von PC-gestützten Übungen, Neurofeedback oder speziellen Konzentrationsspielen. Ziel ist es, deine mentale Leistungsfähigkeit zu erhalten und auszubauen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Motorisch-funktionelle Behandlung'"
                    :subTitle="'Beweglichkeit erhalten und verbessern'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/motoric_functional_treatment.png')"
                    :imgAlt="'Motorisch - Funktionelle Behandlung'"
                    :modalText="'Durch gezieltes Training steigern wir das Bewegungsausmaß deiner Gelenke und Muskeln. Das Ziel ist es, gesunde Haltungs- und Bewegungsmuster zu fördern und Symptome orthopädischer Erkrankungen zu lindern.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Psychisch-funktionelle Behandlung'"
                    :subTitle="'Emotionale Stärke im Alltag'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/psychic_functional_treatment.png')"
                    :imgAlt="'Psychisch- funktionelle Behandlung störungen'"
                    :modalText="'Gemeinsam mit dir entwickeln wir Strategien, um emotionale Belastungen besser zu bewältigen. Ziel ist es, dich emotional zu stabilisieren und deinen Alltag leichter zu meistern. Mit Methoden wie Achtsamkeitstraining, Entspannungsübungen oder Mal- und Spieltherapie helfen wir dir, Herausforderungen kraftsparend und gestärkt anzugehen.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Spieltherapie'"
                    :subTitle="'Spielerisch Emotionen verstehen'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/play_therapy.png')"
                    :imgAlt="'Spieltherapie'"
                    :modalText="'Kinder verarbeiten ihre Sorgen oft im Stillen. In der Spieltherapie öffnen sie sich leichter, indem sie belastende Alltagssituationen mit Spielfiguren nachstellen. Gemeinsam mit der Therapeutin entwickeln sie Lösungen und lernen spielerisch, ihre Gefühle zu regulieren und emotional stabil zu bleiben.'"
                />
            </div>
            <div class="container col-lg-4 col-md-6 col-xs-6">
                <shield-card
                    :title="'Neurofeedback'"
                    :subTitle="'Dein Gehirn im Gleichgewicht'"
                    :imgSrc="require('../../assets/img/leistungen/ocupational_therapy/neurofeedback.png')"
                    :imgAlt="'Neurofeedback'"
                    :modalText="'Neurofeedback misst deine Gehirnaktivität und gibt dir in Echtzeit Rückmeldung, um deine Selbstregulation zu verbessern. Über Animationen auf dem Bildschirm lernst du, ungünstige Muster zu verändern. Diese Methode ist schmerzfrei, ohne Eingriffe in den Körper und ohne Medikamente. Sie wird erfolgreich bei Epilepsie, ADHS, Angststörungen und vielen weiteren Beschwerden eingesetzt. Neurofeedback bietet eine individuelle, datenbasierte Behandlung, die dein Gehirn nachhaltig positiv beeinflusst.'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import ShieldCard from '@/components/Ui/ShieldCard.vue';

export default {
    name: 'OccupationalTherapy',
    components: {
        HeaderWithLine,
        ShieldCard
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style scoped>
.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-stroked {
    font-family: 'HP Simplified';
    color: white;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 3px #08425e;
}

.height {
    height: 100%;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 3.1vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 575px) {
    .text-stroked {
        font-size: 5vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }
}
</style>
