<template>
  <b-modal
      centered
      v-model="modalShow"
      hide-footer
      :title="title"
      >
      <b-list-group>
          <b-list-group-item class="d-flex align-items-center bg-primary text-white border-0 p-0">
            <span class="mr-auto h5 p-0">{{ subTitle }}</span>
            <b-avatar
                rounded="sm"
                :src="imgSrc"
                size="6rem"
                class="avatar-border"
            ></b-avatar>
          </b-list-group-item>
      </b-list-group>
      <img
          class="divider-image"
          src="../../assets/img/decorations/modal_line.png"
      />
      <div class="text-box" v-html="modalText"></div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
    name: 'LeistungModal',
    components: {
        BModal
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: false
        },
        modalText: {
            type: String,
            required: false
        },
        imgSrc: {
            type: String,
            required: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.modalShow = newVal;
            }
        },
        modalShow(newVal) {
            this.$emit('input', newVal);
        }
    },
    data() {
        return {
            modalShow: this.value
        };
    }
};
</script>

<style>
.modal-content {
    background-color: #07425e;
    border: 5px solid #b0c34a;
    border-radius: 25px;
}

.close {
    font-size: 2em;
    color: #b0c34a;
    opacity: 1;
}

.close:hover {
    color: #ffffff;
    opacity: 1;
}

.modal-header {
    color: #ffffff;
    border-bottom: 0;
}

.divider-image {
    width: 100%;
    height: auto;
    margin: 20px 0;
}

.text-box {
    background-color: #ffffff;
    border: 3px solid #b0c34a;
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    word-wrap: break-word;
    text-align: justify;
}

.avatar-border {
    border: 2px solid var(--bs-tertiary);
}

.text-bold {
    font-weight: bold;
}
</style>
