<template>
    <b-card
        img-right
        :img-src="computedImgSrc"
        class="
            card-right
            text-primary
            text-center
            d-flex
            justify-content-center
            align-items-center
            shadow
        "
    >
        <b-card-text>
            <p v-if="isLargeScreen" class="text-primary h5 text-center">
                {{ text }}
            </p>
            <p v-else class="text-primary h6 text-center">
                {{ text }}
            </p>
        </b-card-text>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import blanccoImage from '../../assets/img/decorations/shild_blanco.png';

export default {
    name: 'ThreadCardright',
    components: {
        BCard
    },
    props: {
        text: {
            type: String,
            required: true
        },
        imgSrc: {
            type: String,
            required: false
        },
        imgAlt: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            blanccoImage: blanccoImage,
            windowWidth: window.innerWidth
        };
    },
    computed: {
        computedImgSrc() {
            return this.imgSrc || this.blanccoImage;
        },
        isLargeScreen() {
            return this.windowWidth > 1199;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    }
};
</script>

<style scoped>
.card-right {
    border-color: #b0c34a; /* Устанавливаем цвет бордера */
    border-style: solid; /* Устанавливаем стиль бордера */
    padding: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 10px 10px 0;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.2);
}

.card-right img {
    border-radius: 0 5px 5px 0;
}

@media screen and (max-width: 1199px){
    .card-right img {
        max-height: 200px;
    }
}

@media screen and (max-width: 991px){
    .card-right {
        border-width: 5px 5px 5px 5px;
        border-radius: 10px 10px 10px 10px;
    }

    .card-right img {
        max-height: 150px;
    }
}
</style>
